import { Path } from "../../@core/interfaces/paths.interfaces";


export const paths: Path[] =
    [
        {
            id: 'amount',
            absolutePath: 'amount-enrty/page',
            relativePath: 'page',
            lazyPath: 'amount-entry'
        },
        {
            id: 'email',
            absolutePath: 'email-entry/page',
            relativePath: 'page',
            lazyPath: 'email-entry'
        },
        {
            id: 'success',
            absolutePath: 'transaction-success/page',
            relativePath: 'page',
            lazyPath: 'transaction-success'
        },
        {
            id: 'error',
            absolutePath: 'transaction-error/page',
            relativePath: 'page',
            lazyPath: 'transaction-error'
        },
    ]